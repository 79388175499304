import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../../components/es/layout"
//import Image from "../components/image"
import SEO from "../../../components/es/seo"

import MainImg from "./../../../images/main_bg.jpg"

import shape1 from "./../../../images/dec/1.png"
import shape2 from "./../../../images/dec/2.png"
import shape3 from "./../../../images/dec/3.png"
import shape4 from "./../../../images/dec/4.png"
import shape5 from "./../../../images/dec/5.png"
import shape6 from "./../../../images/dec/6.png"
import shape7 from "./../../../images/dec/7.png"
import shape8 from "./../../../images/dec/8.png"

import product5 from "./../../../images/es/moonamar-jabon-de-manteca-de-karite-y-comino-negro.png"

const CorokotovoUlje = () => (
  <Layout>
    <SEO
      title="MOONAMAR – JABÓN DE MANTECA DE KARITÉ Y COMINO NEGRO"
      description="Jabón MOONAMAR de manteca de karité, comino negro y vitamina D – cuidado suave para pieles sensibles o propensas a alergias."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img className="_img" src={product5} alt="JABÓN DE MANTECA DE KARITÉ Y COMINO NEGRO" title="JABÓN DE MANTECA DE KARITÉ Y COMINO NEGRO" />
              <div className="_info">
                <div className="_inner">
                  <span>MOONAMAR</span>
                  <div className="title">Jabón de manteca de karité con aceite de comino negro</div>
                  <div className="subtitle">
                  Contenido: 100 g
                  </div>
                  <div className="price">1,99 €</div>
                </div>
                <div className="_buttonShop">
                  <Link to="/es/formulario-para-pedidos">
                  PEDIDOS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Descripción del producto</Tab>
                <Tab>Ingredientes y modo de empleo</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                  El jabón de manteca de karité MOONAMAR es delicado con todo tipo de pieles. Está hecho con una base de manteca de karité, vitamina E y aceite de comino negro, que son conocidos por sus cualidades calmantes e hidratantes para la piel. 
                  </p>
                  <p>
                    <strong>- Delicado con la piel</strong>
                    <br />
                    <strong>- Con las propiedades antinflamatorias del comino negro</strong>
                    <br />
                    <strong>- Adecuado para manos, cara, cuerpo y cabellos con caspa</strong>
                    <br />
                    <strong>- También se puede utilizar en pieles con dermatitis, soriasis o acné</strong>
                    <br />
                    <strong>- vegano</strong>
                  </p>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <h3>Ingrediente:</h3>
                  <p>
                  Sodium Palmate, Sodium Palm Kernelate, Aqua, Butyrospermum Parkii (Manteca de karité), Nigella Sativa Seed Oil (Aceite de comino negro), Glicerine, Sodium Chloride, Citric Acid, Tocopherol (Vitamin E).                  </p>
                  <h3>Aplicación:</h3>
                  <p>
                  El jabón de MOONAMAR está recomendado para la higiene y cuidado de pieles secas o delicadas. El jabón puede ser utilizado en las manos, cuerpo y cabello. También es ideal como jabón para el afeitado.                  </p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>

  </Layout>
)

export default CorokotovoUlje
